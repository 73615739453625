import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { generateRoutes } from '../util/functions/routeGenerators'
import { homePath, navRoutes } from './routes'
import NotFoundPage from '../views/NotFoundPage/NotFoundPage'

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigate replace to={homePath} />} />
        {generateRoutes(navRoutes)}
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
