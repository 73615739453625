import { Link, Route } from 'react-router-dom'
import Guardian from '../../router/Guardian'

export const generateLinks = (routes, formatMessage) =>
  routes &&
  routes
    .filter((r) => !r.hidden)
    .map((r) => ({
      key: r.path,
      label: <Link to={r.path}>{formatMessage({ id: r.name })}</Link>,
      icon: r.icon,
    }))

export const generateRoutes = (routes) =>
  routes &&
  routes.map(
    ({
      path,
      layout: Layout,
      component: Component,
      exact = false,
      guardianConfig,
    }) => {
      const withLayoutComp = Layout ? (
        <Layout>
          <Component />
        </Layout>
      ) : (
        <Component />
      )
      return (
        <Route
          key={path}
          path={path}
          exact={exact}
          element={<Guardian {...guardianConfig}>{withLayoutComp}</Guardian>}
        />
      )
    }
  )
