import loadable from '../util/functions/loadable'
import { HomeOutlined } from '@ant-design/icons'

const LogWithParamsLayout = loadable(() =>
  import('../layouts/LogWithParamsLayout/LogWithParamsLayout')
)
const Home = loadable(() => import('../views/Home/Home'))

export const homePath = '/home'

const homeRoute = {
  name: 'home',
  icon: <HomeOutlined />,
  path: homePath,
  layout: LogWithParamsLayout,
  component: Home,
  guardianConfig: {
    protect: false,
  },
}

export const navRoutes = [homeRoute]
