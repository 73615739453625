import AppRouter from './router/AppRouter'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './redux/store'
import IntlWrapper from './intl/IntlWrapper'
import 'antd/dist/reset.css'
import './assets/css/App.css'

const App = () => (
  <Provider store={store}>
    <PersistGate loading='loading' persistor={persistor}>
      <IntlWrapper>
        <AppRouter />
      </IntlWrapper>
    </PersistGate>
  </Provider>
)

export default App
