import { useIntl } from 'react-intl'
import { Result } from 'antd'

const NotFoundPage = () => {
  const { formatMessage } = useIntl()

  return (
    <div className='container view'>
      <Result
        status='404'
        title='404'
        subTitle={formatMessage({ id: '404Label' })}
      />
    </div>
  )
}

export default NotFoundPage
