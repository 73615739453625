export const getValidRoutes = (routes, token, user) =>
  routes.filter((route) => {
    const {
      protect = false,
      roles = '#',
      customValidation = () => false,
      concatValidations = false,
    } = route.guardianConfig || {}

    const { routeValid } = isRouteValid(token, user, roles, protect)

    return concatConditions(
      concatValidations,
      routeValid,
      customValidation({
        /** Provide any data from user needed on routes **/
      })
    )
  })

/**
 *
 * @param {String} token from user
 * @param {Object} user
 * @param {Array<String> | String} roles from the route config
 * @param {boolean} protect from the route config
 * @returns {{validRole: boolean, validUser: boolean, routeValid: boolean}}
 */
export const isRouteValid = (token, user, roles, protect) => {
  const validUser = user && user._id && token
  const validRole = roles === '#' || roles.some((r) => r === user?.role || '')

  const routeValid = protect
    ? validUser && validRole
    : !validUser || (validUser && validRole)

  return { validUser, validRole, routeValid }
}

/**
 * It concats two conditions using && if isInclusive or ||
 * @param {boolean} isInclusive
 * @param {boolean} condition1
 * @param {boolean} condition2
 * @returns {boolean}
 */
export const concatConditions = (isInclusive, condition1, condition2) =>
  isInclusive ? condition1 && condition2 : condition1 || condition2
