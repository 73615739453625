import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { selectSessionUser, selectToken } from '../redux/session/selectors'
import {
  concatConditions,
  isRouteValid,
} from '../util/functions/secureFunctions'

const Guardian = ({
  token,
  sessionUser,
  children,
  protect,
  roles,
  customValidation,
  concatValidations,
  redirectOnSession,
  pathToRedirect,
  escapeTo,
}) => {
  const navigate = useNavigate()
  const { validUser, routeValid } = isRouteValid(
    token,
    sessionUser,
    roles,
    protect
  )

  const shouldRender = concatConditions(
    concatValidations,
    routeValid,
    customValidation({
      /** Provide any data from user needed on routes **/
    })
  )

  useEffect(() => {
    if (redirectOnSession && validUser) {
      navigate(pathToRedirect)
    }

    // eslint-disable-next-line
  }, [redirectOnSession, pathToRedirect, validUser])

  return shouldRender ? children : <Navigate to={escapeTo} />
}

Guardian.defaultProps = {
  protect: false,
  roles: '#',
  customValidation: () => false,
  concatValidations: false,
  redirectOnSession: false,
  pathToRedirect: '/',
  escapeTo: '/',
}

const mapStateToProps = (state) => ({
  token: selectToken(state),
  sessionUser: selectSessionUser(state),
})

export default connect(mapStateToProps, () => ({}))(Guardian)
